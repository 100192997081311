/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;

:root {
  --main-color: #1c4182;
  --main-dark-color: #001b55;
  --main-light-color: #2a479b;
  --accent-color: #f5a115;
  --light-background-color: #f4f5f6;

  --light-blue: #d9e2ff;
  --text-on-white: #3f4759;
  --text-on-gray: #44474f;
  --background-dark: #001a43;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
body {
  margin: 0;
  background: var(--background-dark);
}

.mat-mdc-menu-panel {
  max-width: 300px !important;
}

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
  )
);

@include mat.core();
@include mat.all-component-themes($my-theme);

.room-id-container {
  padding: 1rem !important;
  border-top: 0.5px solid lightgrey;

  h4 {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    button {
      flex-shrink: 0;
    }
  }
}

.card-footer-actions {
  border-top: 0.5px solid lightgrey;
  padding: 1rem !important;

  &.align-right {
    display: flex !important;
    justify-content: flex-end;
  }

  button:not(:last-of-type) {
    margin-right: 0.5rem;
  }

  &.extra-padding {
    padding-bottom: 2rem;
  }
}

.avatar,
.avatar-button {
  display: flex;
  align-items: center;
  justify-content: center;

  &.avatar-button {
    padding: 0;
    img {
      height: 40px;
      width: 40px;
    }
  }

  .avatar-text {
    height: 90%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    background: #f5a115 !important;
    border-radius: 50%;
  }
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.statistic {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
}

.note-wrapper {
  padding: 0px 16px;
}

@keyframes appear {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  70% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: transleX(150%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.center-icon-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 6px !important;
}

.navbar {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;
}

.navbar-right,
.navbar-left {
  padding: 1rem;

  @media screen and (min-width: 800px) {
    padding: 1rem 2.5rem;
  }
}

.navbar-right {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;

  .create-account-button {
    @media screen and (max-width: 500px) {
      .mdc-button__label {
        display: none;
      }
      mat-icon {
        margin-right: 0;
      }
    }
  }
}

.custom-tab-group {
  .mdc-tab-indicator {
    display: none;
  }
  .mat-mdc-tab-header {
    border-radius: 46px;
    background-color: #edf0ff !important;
  }

  .mat-mdc-tab {
    .mdc-tab__text-label {
      color: #44474f !important;
      font-weight: bold;
    }
    &.mdc-tab--active {
      background-color: #d9e2ff !important;
      border-radius: 46px;
      .mdc-tab__text-label {
        color: #345ca8 !important;
      }
    }
  }
}

.tab-content {
  padding-top: 1rem;
}

.tab-section {
  background: var(--light-background-color);
  border-radius: 16px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.premium-lock {
  color: var(--accent-color) !important;
}

.premium-banner {
  background: #edf0ff;
  border: 1px solid #345ca8;
  border-radius: 18px;
  padding: 1rem;
  color: white;
  font-size: 0.8rem;
  margin-bottom: 1rem;

  p {
    color: #15448f;
  }

  .premium-banner-buttons {
    display: flex;
    flex-wrap: w;
    gap: 0.5rem;

    .learn-more {
      color: #575e71;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.2rem;
    font-size: 1rem;
    color: #001a43;
  }
}

.dialog-title {
  .beta-chip {
    background-color: var(--light-blue) !important;
    margin-left: 0.5rem;
  }
}

.features-list {
  padding: 0;

  li {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;

    mat-icon {
      color: #48a81c;
      flex-shrink: 0;
    }
  }
}

.full-page-container {
  min-height: 100vh;
}

.full-screen-modal {
  border-radius: 0;
}

.snackbar-error {
  .mdc-snackbar__surface {
    background: #93000a !important;
    color: #ffdad6 !important;
  }
}

.menu-item-with-badge {
  display: flex;
  gap: 0.8rem;
  align-items: center;

  .menu-item-badge {
    display: inline-flex;
    background: #725573;
    color: white;
    font-size: 12px;
    padding: 0px 8px;
    border-radius: 40px;
  }
}
